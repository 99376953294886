import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Le budget du téléphone`}</h1>
    <p>{`Prenons un objectif apparemment peu ambitieux `}<strong parentName="p">{`quantifier, comprendre et faire comprendre l'utilisation du téléphone et les tendances en cours`}</strong>{`. `}</p>
    <p>{`La démarche consiste à identifier, dans un premier temps, les questions qui éclairent/caractérisent l'utilisation du téléphone. Et pour chaque question, l'ensemble des indicateurs qui permettent d'y répondre. Enfin de les représenter d'une manière ergonomique et compacte. `}</p>
    <h3>{`Quel est notre parc téléphonique ?`}</h3>
    <ul>
      <li parentName="ul">{`Nombre de lignes fixes  `}</li>
      <li parentName="ul">{`Nombre d'abonnements mobiles `}</li>
      <li parentName="ul">{`Comparaison de ces deux indicateurs `}</li>
      <li parentName="ul">{`Parts dans les couts  `}</li>
      <li parentName="ul">{`Répartition sur chaque site`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "533px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "29.447852760736193%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAAsSAAALEgHS3X78AAABEklEQVQY021PyU7DMBTM//8CZ8SZY4MqkFp6aahQS1u6kNiukjQ2DontbE7sh6vcEHOc92bzAEBVqhSirmtjLPyF7QejGgP/wcuy7MmfTKfT+Wx+DkMn7/TAvvNO9+7sHCnjRdUrJWjG6JX+ODDG4oRT5jVNLaXsutYxh/3OX+LHBZmt403I9+FVKilEyYSNjoHh65TlnBB9/yAnfhysPGtvVa0x0VeEQ/TyfsmldoybUBRl41BXRQPk+AoiyJUuyQXeVrrXnDFvGIZRL8ryfDrtEBvdxs5aazN0VMLnxwLwXY6eZbSFzVZbk6bpTXwhBCHctq17dVONC7W2qhTCZExmokMJBRWiw5JnMVR1ksQYk19YbU6edlU5OQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "parc",
          "title": "parc",
          "src": "/static/9c1aae167509a3e9c4f81cc17790a3e5/a1aa0/parc.png",
          "srcSet": ["/static/9c1aae167509a3e9c4f81cc17790a3e5/222b7/parc.png 163w", "/static/9c1aae167509a3e9c4f81cc17790a3e5/ff46a/parc.png 325w", "/static/9c1aae167509a3e9c4f81cc17790a3e5/a1aa0/parc.png 533w"],
          "sizes": "(max-width: 533px) 100vw, 533px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3>{`Quel usage faisons nous du téléphone ?`}</h3>
    <ul>
      <li parentName="ul">{`Nombre d'appels `}</li>
      <li parentName="ul">{`Origines/destinations des appels `}</li>
      <li parentName="ul">{`Lignes ou mobiles utilisés `}</li>
      <li parentName="ul">{`Part intra-Groupe `}</li>
      <li parentName="ul">{`Parts dans les couts  `}</li>
      <li parentName="ul">{`Évolutions sur les 12 mois précédents`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "540px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "52.760736196319016%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAAB+UlEQVQozzVS224TMRDdv+cneOERCZAQRTwgUVWIgtJKqUKbKJtskt3u1Xbs9XXttb3LpBV+GHmOPOfMnHHSNA3GmDE2TdM8z1VZHvaH/JRjhCGdpgB4jFEKkW63z0XhYxTjqAY4NknTtKmBoWGs9z4YYwjBXddBmndqldZFS6WgVhFAeilNlrFft2i97vs+AXogZpTud3uCyKAVZVwIOU/h48/8x7J6eMoIqsNA4KXU2q03899Hnu6ElAlUYoQ2680u3VVlpbSSUnMuAMxb4UOk53NPccg/RHlkFI/7bNZGUipAGWYry6prW1BGbQezSSmhJSF1RQaYuq5bwbr49CYc37fF3Xh9PX+5Yr//8LZLBjNYa733CKGmqr3WUOrG0VqHmIG+KO0lJ14WYZoZow6MnCajjVIqIRhDt+BQVTXnM9XGSICFUEo/nSC1Xdv1jIyGxRik4Lauo7WDeSlerVZZli2XS/AcLHHOgZofR9jP/P8AArLhsrlLvFwgxpgAgfejVLrEWg7B2eEiLNXgxptV9/3++WGTS21C9m4qPnH86BeL+fMVv7vXZ3px+/V7EEIQPlsf5OB6qHX226K8fazT/QlW5bZvZ75lgrjrm/nqKzocOWMJpbQoSrAX6kOIsJtXrtcoOD8dj9NLEkJoWgwXY212Oilt/gFVJGTu8KUktgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "appels",
          "title": "appels",
          "src": "/static/ceeb275050e10fa0017bb389d88b71e9/07484/appels.png",
          "srcSet": ["/static/ceeb275050e10fa0017bb389d88b71e9/222b7/appels.png 163w", "/static/ceeb275050e10fa0017bb389d88b71e9/ff46a/appels.png 325w", "/static/ceeb275050e10fa0017bb389d88b71e9/07484/appels.png 540w"],
          "sizes": "(max-width: 540px) 100vw, 540px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3>{`Quelle est notre consommation et combien cela coute`}{` `}{`?`}</h3>
    <ul>
      <li parentName="ul">{`Durées des appels `}</li>
      <li parentName="ul">{`Cout apparent à la minute `}</li>
      <li parentName="ul">{`Cout global `}</li>
      <li parentName="ul">{`Évolution sur les 12 derniers mois`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "534px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "45.39877300613497%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsSAAALEgHS3X78AAABCklEQVQoz3WR646DIBCFff+X3Lp1Y0EE5aJSqt1PJvXHJnsSyTicCwyNMabv+++2/brd2rad5/lVilIqhPCucNblnKX23iulKawZB20a2IMxSj3u967rfubZl1Ksdcuy7PteymuapnVd9wrEWmsIKUbcm+M46GIGe9BDSomazusDCKz7B3KElKIZx0ZkMUZkMcScnyIAbBEiFsjo8CsFK5JTHLwfRztay4cFVNGwcttSAftZIVvo1207xTAI39aNuwmVDjxI73+AFzE1OQTnHBNgeOglE/GVJkbgCmdrI1kugMxxbOdoifc1yD+BMkvmA/lMxkrzfA9eVzEGvHOFREkt15Hk+oSFZ/sFGZkLKXU9O2MAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "teletotal",
          "title": "teletotal",
          "src": "/static/927b446fe5d8ff0a8c9fa4dc81d598c7/a07a7/teletotal.png",
          "srcSet": ["/static/927b446fe5d8ff0a8c9fa4dc81d598c7/222b7/teletotal.png 163w", "/static/927b446fe5d8ff0a8c9fa4dc81d598c7/ff46a/teletotal.png 325w", "/static/927b446fe5d8ff0a8c9fa4dc81d598c7/a07a7/teletotal.png 534w"],
          "sizes": "(max-width: 534px) 100vw, 534px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Le tableau de bord peut être alors construit à partir de ces trois blocs de questions/réponses. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      